var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full" },
    [
      _vm._l(
        _vm.macroDescriptions,
        function ({ name, description, space, example }) {
          return [
            space
              ? _c(
                  "div",
                  {
                    key: `detail_${name}_space`,
                    staticClass: "w-full item mt-0",
                  },
                  [_vm._v("...")]
                )
              : !example
              ? _c(
                  "div",
                  {
                    key: `detail_${name}`,
                    staticClass: "flex justify-between item",
                  },
                  [
                    _c("span", { staticClass: "w-2/6 font-bold" }, [
                      _vm._v(_vm._s(name)),
                    ]),
                    _c("span", { staticClass: "w-4/6" }, [
                      _vm._v(_vm._s(description) + " "),
                    ]),
                  ]
                )
              : _c(
                  "div",
                  {
                    key: `detail_${name}_example`,
                    staticClass: "flex justify-between item",
                  },
                  [
                    _c("span", { staticClass: "w-2/6 font-bold" }, [
                      _vm._v(_vm._s(name)),
                    ]),
                    "CUSTOM ITEM VALUE EXAMPLE" === name
                      ? _c("div", { staticClass: "w-4/6 flex flex-col" }, [
                          _c("p", [_vm._v("&cv=686f6c61")]),
                          _c("span", [_vm._v("${CUSTOM_ITEM_VALUE}=686f6c61")]),
                          _c("span", [
                            _vm._v("${CUSTOM_ITEM_VALUE_1}=686f6c61"),
                          ]),
                          _c("p", { staticClass: "mt-2 mb-2" }, [
                            _vm._v(
                              "If we receive in the tracking link: &ci=686f6c61&ci=6164696f73"
                            ),
                          ]),
                          _c("span", [
                            _vm._v("${CUSTOM_ITEM_VALUE}=686f6c61,6164696f73"),
                          ]),
                          _c("span", [
                            _vm._v("${CUSTOM_ITEM_VALUE_1}=686f6c61"),
                          ]),
                          _c("span", [
                            _vm._v("${CUSTOM_ITEM_VALUE_2}=6164696f73"),
                          ]),
                        ])
                      : "CUSTOM ITEM NAME EXAMPLE" === name
                      ? _c("div", { staticClass: "w-4/6 flex flex-col" }, [
                          _c("p", [_vm._v("&ci=hola")]),
                          _c("span", [_vm._v("${CUSTOM_ITEM_NAME}=hola")]),
                          _c("span", [_vm._v("${CUSTOM_ITEM_NAME_1}=hola")]),
                          _c("p", { staticClass: "mt-2 mb-2" }, [
                            _vm._v(
                              "If we receive in the tracking link: &ci=hola&ci=adios"
                            ),
                          ]),
                          _c("span", [
                            _vm._v("${CUSTOM_ITEM_NAME}=hola,adios"),
                          ]),
                          _c("span", [_vm._v("${CUSTOM_ITEM_NAME_1}=hola")]),
                          _c("span", [_vm._v("${CUSTOM_ITEM_NAME_2}=adios")]),
                        ])
                      : _vm._e(),
                  ]
                ),
          ]
        }
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }