var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-col w-full mt-5 form-row lg:flex-row" },
    [
      _c(
        "div",
        { staticClass: "w-full lg:w-1/2 lg:mr-4" },
        [
          _c(
            "sun-label-group",
            { attrs: { text: _vm.labelLeft } },
            [
              _c("sun-input", {
                attrs: {
                  type: "text",
                  name: "param",
                  placeholder: _vm.leftPlaceholder,
                  minlength: 1,
                  maxlength: 50,
                  "text-error": _vm.paramKeyError || "The field is required",
                  error: !!_vm.paramKeyError,
                  required: "required",
                },
                on: {
                  blur: function ($event) {
                    return _vm.emitBlur("paramKey", $event)
                  },
                  focus: function ($event) {
                    return _vm.emitBlur("paramKey", $event)
                  },
                  input: _vm.emitChange,
                },
                model: {
                  value: _vm.paramKey,
                  callback: function ($$v) {
                    _vm.paramKey = $$v
                  },
                  expression: "paramKey",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "w-full mt-6 lg:w-1/2 lg:ml-4 lg:mt-0" },
        [
          _c(
            "sun-label-group",
            { attrs: { text: _vm.labelRight } },
            [
              _c("sun-select", {
                attrs: {
                  value: _vm.selectValue,
                  name: "value",
                  placeholder: _vm.rightPlaceholder,
                  options: _vm.allOption,
                  "track-by": "value",
                  label: "name",
                  required: "required",
                  "close-on-select": "",
                  "tag-placeholder": "Using this text",
                  taggable: "",
                  disabled: !_vm.paramKey,
                  "text-error": _vm.paramValueError,
                  error: !!_vm.paramValueError,
                },
                on: {
                  change: function ($event) {
                    return _vm.changeValue($event.value)
                  },
                  blur: function ($event) {
                    return _vm.emitBlur("paramValue", $event)
                  },
                  focus: function ($event) {
                    return _vm.emitBlur("paramValue", $event)
                  },
                  tag: _vm.addValue,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "w-full mt-4 mb-3 ml-4 md:mb-1 md:mt-0 md:w-auto",
          class: _vm.btnRemoveClass,
        },
        [
          _c(
            "sun-button",
            {
              staticClass:
                "w-full text-white bg-gray-700 rounded shadow-md hover:bg-gray-900 custom-p-1 md:w-auto text-xs",
              attrs: { color: "red", variant: "pill" },
              on: {
                click: function ($event) {
                  return _vm.$emit("remove")
                },
              },
            },
            [_vm._v(" Remove ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }