const macroDescriptions = [
  {
    name: 'AFFILIATION_CODE',
    description: 'Unique Click ID. Will be replaced with the value received in &afc= parameter of the tracking link.',
  },
  { name: 'TRANSACTION_ID', description: 'Unique Transaction ID received by the client.' },
  { name: 'EXPENSE', description: 'Publisher Cost in euros.' },
  { name: 'DATE', description: 'Lead Date in Timestamp format.' },
  { name: 'EVENT_ID', description: 'Event ID, empty for lead.' },
  { name: 'EVENT_NAME', description: 'Event Name, empty for lead.' },
  {
    name: 'EVENT_SESSION_ID',
    description: 'Unique identifier per sessión. It is used  to track the activity of a user.',
  },
  {
    name: 'SUB_ID',
    description:
      'Sub Publisher or Affiliate ID. Will be replaced with the value received in &sid= parameter of the tracking link.',
  },
  { name: 'CUSTOM_ITEM_NAME', description: 'Value received in the parameter &ci= of the tracking Link.' },
  { name: 'CUSTOM_ITEM_NAME_1', description: 'Value received in the first parameter &ci= of the tracking Link.' },
  { name: 'CUSTOM_ITEM_NAME_X', space: true },
  { name: 'CUSTOM_ITEM_NAME_5', description: 'Value received in the fifth parameter &ci= of the tracking Link.' },
  { name: 'CUSTOM ITEM NAME EXAMPLE', example: true },
  { name: 'CUSTOM_ITEM_VALUE', description: 'Value received in the parameter &cv= of the tracking Link.' },
  { name: 'CUSTOM_ITEM_VALUE_1', description: 'Value received in the parameter &cv= of the tracking Link.' },
  { name: 'CUSTOM_ITEM_VALUE_X', space: true },
  { name: 'CUSTOM_ITEM_VALUE_5', description: 'Value received in the fifth parameter &cv= of the tracking Link.' },
  { name: 'CUSTOM ITEM VALUE EXAMPLE', example: true },
];

export default Object.freeze({
  macroDescriptions: macroDescriptions.map(({ name, description, space, example }) => ({
    name: example ? name : `\${${name}}`,
    example,
    space,
    description,
  })),
  urlCreatorOptions: [
    'AFFILIATION_CODE',
    'TRANSACTION_ID',
    'EXPENSE',
    'DATE',
    'EVENT_ID',
    'EVENT_NAME',
    'EVENT_SESSION_ID',
    'SUB_ID',
    'CUSTOM_ITEM_NAME',
    'CUSTOM_ITEM_NAME_1',
    'CUSTOM_ITEM_NAME_2',
    'CUSTOM_ITEM_NAME_3',
    'CUSTOM_ITEM_NAME_4',
    'CUSTOM_ITEM_NAME_5',
    'CUSTOM_ITEM_VALUE',
    'CUSTOM_ITEM_VALUE_1',
    'CUSTOM_ITEM_VALUE_2',
    'CUSTOM_ITEM_VALUE_3',
    'CUSTOM_ITEM_VALUE_4',
    'CUSTOM_ITEM_VALUE_5',
  ].map(macro => ({
    name: `\${${macro}}`,
    value: `\${${macro}}`,
  })),
});
