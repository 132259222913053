<template>
  <div class="url-creator">
    <div class="flex flex-col w-full form-row lg:flex-row">
      <div class="w-full">
        <sun-label-group :text="label">
          <div class="w-full">
            <sun-input
              type="text"
              name="offersUrl"
              :value="value"
              :minlength="5"
              :text-error="invalidUrlText || textError"
              :error="!!_error"
              class="mb-2"
              placeholder="https://example.com?hash=${EVENT_SESSION_ID}"
              :required="required"
              @input="parseUrl($event)"
            />
            <span v-if="showWarningText" class="text-sm flex flex-row items-center align-baseline text-yellow-900">
              <InfoSvg class="w-3 h-3 mb-1" />
              {{ warningText }}
            </span>
          </div>
          <template v-if="showDetails" #info-popover>
            <div class="bg-white text-xs text-gray-800 px-2 py-1 border border-gray-400 rounded shadow-lg">
              <p class="text-base p-2">Available Tokens:</p>
              <div class="flex flex-col border border-b-0">
                <slot name="info-popover-content">
                  <div
                    v-for="{ name, description } in options"
                    :key="`detail_${name}`"
                    class="flex justify-between p-1 border-b-2 w-full"
                  >
                    <span class="w-2/6 font-bold">{{ name }}</span>
                    <span class="w-4/6 text-left">{{ description }} </span>
                  </div>
                </slot>
              </div>
            </div>
          </template>
        </sun-label-group>
      </div>
    </div>
    <sun-form ref="urlCreatorForm">
      <url-query-string
        v-for="query in queryString"
        :key="query[0]"
        :options="optionsUpdated"
        :value="query[1]"
        unique
        class="url-creator-params"
        @change="changeUrlParams(query[0], $event)"
        @remove="removeUrlParams(query[0])"
      />

      <div v-if="value" class="w-full mt-4 md:w-auto url-creator-add">
        <sun-button
          class="w-full text-white bg-gray-700 rounded shadow-md hover:bg-gray-900 custom-p-1 md:w-auto text-xs"
          color="gray"
          variant="pill"
          @click="addUrlParam()"
        >
          Add
        </sun-button>
      </div>
    </sun-form>
  </div>
</template>

<script>
import UrlQueryString from './UrlQueryString/UrlQueryString';

export default {
  components: {
    UrlQueryString,
    InfoSvg: () => import('@/components/icons/InfoSvg.vue'),
  },
  props: {
    label: { type: String, default: 'Url' },
    value: { type: String, default: null },
    required: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    textError: { type: String, default: 'The url field is required.' },
    /** @type {array.<{{name:string, value:string, description:string}}>} */
    options: { type: Array, default: () => [] },
    showDetails: { type: Boolean, default: false },
    warningText: { type: String, default: null },
  },
  data: () => ({
    /** @type {URL|null} */
    baseUrl: null,
    queryString: new Map(),
    mapQueryStringKey: 0,
    invalidUrl: false,
    optionsUpdated: [],
  }),
  computed: {
    _error() {
      return this.error || !!this.invalidUrlText;
    },
    invalidUrlText() {
      return this.invalidUrl ? 'Invalid URL' : null;
    },
    showWarningText() {
      return this.warningText && !this._error;
    },
  },
  watch: {
    value: 'parseUrl',
  },
  created() {
    if (this.value) {
      this.parseUrl(this.value);
    }
    this.optionsUpdated = this.options;
  },
  methods: {
    makeURL(value) {
      try {
        if (value.indexOf('&&') !== -1 || value.indexOf('&=') !== -1) {
          return null;
        }

        const re =
          /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,10}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
        if (re.test(value)) {
          return new URL(value);
        }

        return null;
      } catch (err) {
        return null;
      }
    },
    disableOptions() {
      this.optionsUpdated.forEach(opt => {
        opt.$isDisabled = false;
      });
      this.queryString.forEach(({ value }) => {
        const optionDisabled = this.optionsUpdated.find(option => option.value === value);
        if (optionDisabled) {
          this.$set(optionDisabled, '$isDisabled', true);
          optionDisabled.$isDisabled = true;
        }
      });
    },
    isQueryStringDisabled(index) {
      return index + 1 !== this.queryString.size;
    },
    parseUrl(value) {
      if (!value) {
        this.resetInputUrl();
      }

      this.queryString = new Map();
      this.mapQueryStringKey = 0;

      const url = this.makeURL(value);
      if (url === null) {
        this.invalidUrl = this.required;
        return;
      }
      this.invalidUrl = false;

      this.baseUrl = url.toString().split('?')[0];
      this.updateInputUrl(value);

      this.createParamsFromQueryString(url.search);

      this.disableOptions();
    },
    updateInputUrl(value = null) {
      this.$emit('input', value);
    },
    updateQueryString() {
      try {
        const url = this.baseUrl + this.createQueryStringFromParams();
        const finalUrl = decodeURIComponent(url);
        this.updateInputUrl(finalUrl);
        this.disableOptions();
      } catch {
        this.invalidUrl = true;
      }
    },
    createQueryStringFromParams() {
      let queryString = '?';
      let addAmpersand = false;

      this.queryString.forEach(({ param, value }) => {
        if (addAmpersand && param) {
          queryString += '&';
        } else {
          addAmpersand = true;
        }

        if (value?.length && param) {
          queryString += `${param}=${value}`;
        } else if (value === '') {
          queryString += `${param}=`;
        } else if (typeof value === 'undefined') {
          queryString += `${param}`;
        }
      });

      return queryString;
    },
    createParamsFromQueryString(queryString) {
      if (!queryString?.length) return;

      const cleanQueryString = queryString.substring(1, queryString.length);
      const queryStringGroups = cleanQueryString.split('&');

      queryStringGroups.forEach(group => {
        const equalPosition = group.indexOf('=');

        if (equalPosition === -1) {
          this.addUrlParam({ param: group, value: undefined });
        } else {
          const param = group.substr(0, equalPosition);
          const value = group.substr(equalPosition + 1, group.length);
          this.addUrlParam({ param, value });
        }
      });
    },
    changeUrlParams(index, { param, value }) {
      const query = this.queryString.get(index);
      query.param = param;
      query.value = value;
      this.updateQueryString();
    },
    removeUrlParams(index) {
      const aux = this.queryString;
      this.queryString = null;
      aux.delete(index);
      this.queryString = aux;
      this.updateQueryString();
    },
    addUrlParam(event = { value: '', param: '' }) {
      let addElement = true;
      this.queryString.forEach(({ param, value }) => {
        addElement = !!param && !!value;
      });
      if (addElement) {
        const aux = this.queryString;
        this.queryString = null;
        aux.set(this.mapQueryStringKey, event);
        this.queryString = aux;
        this.mapQueryStringKey++;
      } else {
        this.$refs.urlCreatorForm.submit();
      }
    },
    resetInputUrl() {
      this.updateInputUrl();
    },
  },
};
</script>

<style scoped></style>
