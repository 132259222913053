<template>
  <div class="w-full">
    <template v-for="{ name, description, space, example } in macroDescriptions">
      <div v-if="space" :key="`detail_${name}_space`" class="w-full item mt-0">...</div>

      <div v-else-if="!example" :key="`detail_${name}`" class="flex justify-between item">
        <span class="w-2/6 font-bold">{{ name }}</span>
        <span class="w-4/6">{{ description }} </span>
      </div>

      <div v-else :key="`detail_${name}_example`" class="flex justify-between item">
        <span class="w-2/6 font-bold">{{ name }}</span>
        <div v-if="'CUSTOM ITEM VALUE EXAMPLE' === name" class="w-4/6 flex flex-col">
          <p>&cv=686f6c61</p>
          <span>${CUSTOM_ITEM_VALUE}=686f6c61</span>
          <span>${CUSTOM_ITEM_VALUE_1}=686f6c61</span>
          <p class="mt-2 mb-2">If we receive in the tracking link: &ci=686f6c61&ci=6164696f73</p>
          <span>${CUSTOM_ITEM_VALUE}=686f6c61,6164696f73</span>
          <span>${CUSTOM_ITEM_VALUE_1}=686f6c61</span>
          <span>${CUSTOM_ITEM_VALUE_2}=6164696f73</span>
        </div>
        <div v-else-if="'CUSTOM ITEM NAME EXAMPLE' === name" class="w-4/6 flex flex-col">
          <p>&ci=hola</p>
          <span>${CUSTOM_ITEM_NAME}=hola</span>
          <span>${CUSTOM_ITEM_NAME_1}=hola</span>

          <p class="mt-2 mb-2">If we receive in the tracking link: &ci=hola&ci=adios</p>
          <span>${CUSTOM_ITEM_NAME}=hola,adios</span>
          <span>${CUSTOM_ITEM_NAME_1}=hola</span>
          <span>${CUSTOM_ITEM_NAME_2}=adios</span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    macroDescriptions: { type: Array, required: true },
  },
};
</script>

<style scoped>
.item {
  @apply border-b-2 w-full px-1;
  margin-top: 5px;
}
</style>
