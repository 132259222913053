var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "url-creator" },
    [
      _c("div", { staticClass: "flex flex-col w-full form-row lg:flex-row" }, [
        _c(
          "div",
          { staticClass: "w-full" },
          [
            _c(
              "sun-label-group",
              {
                attrs: { text: _vm.label },
                scopedSlots: _vm._u(
                  [
                    _vm.showDetails
                      ? {
                          key: "info-popover",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "bg-white text-xs text-gray-800 px-2 py-1 border border-gray-400 rounded shadow-lg",
                                },
                                [
                                  _c("p", { staticClass: "text-base p-2" }, [
                                    _vm._v("Available Tokens:"),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex flex-col border border-b-0",
                                    },
                                    [
                                      _vm._t(
                                        "info-popover-content",
                                        function () {
                                          return _vm._l(
                                            _vm.options,
                                            function ({ name, description }) {
                                              return _c(
                                                "div",
                                                {
                                                  key: `detail_${name}`,
                                                  staticClass:
                                                    "flex justify-between p-1 border-b-2 w-full",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "w-2/6 font-bold",
                                                    },
                                                    [_vm._v(_vm._s(name))]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "w-4/6 text-left",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(description) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        }
                      : null,
                  ],
                  null,
                  true
                ),
              },
              [
                _c(
                  "div",
                  { staticClass: "w-full" },
                  [
                    _c("sun-input", {
                      staticClass: "mb-2",
                      attrs: {
                        type: "text",
                        name: "offersUrl",
                        value: _vm.value,
                        minlength: 5,
                        "text-error": _vm.invalidUrlText || _vm.textError,
                        error: !!_vm._error,
                        placeholder:
                          "https://example.com?hash=${EVENT_SESSION_ID}",
                        required: _vm.required,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.parseUrl($event)
                        },
                      },
                    }),
                    _vm.showWarningText
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "text-sm flex flex-row items-center align-baseline text-yellow-900",
                          },
                          [
                            _c("InfoSvg", { staticClass: "w-3 h-3 mb-1" }),
                            _vm._v(" " + _vm._s(_vm.warningText) + " "),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _c(
        "sun-form",
        { ref: "urlCreatorForm" },
        [
          _vm._l(_vm.queryString, function (query) {
            return _c("url-query-string", {
              key: query[0],
              staticClass: "url-creator-params",
              attrs: {
                options: _vm.optionsUpdated,
                value: query[1],
                unique: "",
              },
              on: {
                change: function ($event) {
                  return _vm.changeUrlParams(query[0], $event)
                },
                remove: function ($event) {
                  return _vm.removeUrlParams(query[0])
                },
              },
            })
          }),
          _vm.value
            ? _c(
                "div",
                { staticClass: "w-full mt-4 md:w-auto url-creator-add" },
                [
                  _c(
                    "sun-button",
                    {
                      staticClass:
                        "w-full text-white bg-gray-700 rounded shadow-md hover:bg-gray-900 custom-p-1 md:w-auto text-xs",
                      attrs: { color: "gray", variant: "pill" },
                      on: {
                        click: function ($event) {
                          return _vm.addUrlParam()
                        },
                      },
                    },
                    [_vm._v(" Add ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }