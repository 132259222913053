<template>
  <div class="flex flex-col w-full mt-5 form-row lg:flex-row">
    <div class="w-full lg:w-1/2 lg:mr-4">
      <sun-label-group :text="labelLeft">
        <sun-input
          v-model="paramKey"
          type="text"
          name="param"
          :placeholder="leftPlaceholder"
          :minlength="1"
          :maxlength="50"
          :text-error="paramKeyError || 'The field is required'"
          :error="!!paramKeyError"
          required="required"
          @blur="emitBlur('paramKey', $event)"
          @focus="emitBlur('paramKey', $event)"
          @input="emitChange"
        />
      </sun-label-group>
    </div>
    <div class="w-full mt-6 lg:w-1/2 lg:ml-4 lg:mt-0">
      <sun-label-group :text="labelRight">
        <sun-select
          :value="selectValue"
          name="value"
          :placeholder="rightPlaceholder"
          :options="allOption"
          track-by="value"
          label="name"
          required="required"
          close-on-select
          tag-placeholder="Using this text"
          taggable
          :disabled="!paramKey"
          :text-error="paramValueError"
          :error="!!paramValueError"
          @change="changeValue($event.value)"
          @blur="emitBlur('paramValue', $event)"
          @focus="emitBlur('paramValue', $event)"
          @tag="addValue"
        />
      </sun-label-group>
    </div>
    <div class="w-full mt-4 mb-3 ml-4 md:mb-1 md:mt-0 md:w-auto" :class="btnRemoveClass">
      <sun-button
        class="w-full text-white bg-gray-700 rounded shadow-md hover:bg-gray-900 custom-p-1 md:w-auto text-xs"
        color="red"
        variant="pill"
        @click="$emit('remove')"
      >
        Remove
      </sun-button>
    </div>
  </div>
</template>

<script>
import { deepClone } from '@/utils/deepClone';

export default {
  props: {
    /** @type {{ new () : {{param:string, value:string}} }} */
    value: { type: Object, default: null },
    /** @type {{ new () : Array.<{name:string, value:string}> }} */
    options: { type: Array, default: () => [], required: true },
    labelLeft: { type: String, default: 'Key' },
    leftPlaceholder: { type: String, default: '' },
    labelRight: { type: String, default: 'Value' },
    rightPlaceholder: { type: String, default: 'Select option' },
  },
  data: () => ({
    paramKey: null,
    paramKeyError: null,
    paramValue: null,
    paramValueError: null,
    customValue: '',
  }),
  computed: {
    btnRemoveClass() {
      return this.paramKeyError || this.paramValueError ? 'self-center' : 'self-end';
    },
    selectValue() {
      return this.paramValue?.value ? this.paramValue : null;
    },
    allOption() {
      if (this.customValue) {
        return [{ value: this.customValue, name: this.customValue }].concat(this.options);
      }
      return this.options;
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler: 'parseValueProp',
    },
  },
  methods: {
    parseValueProp({ param, value }) {
      this.customValue = '';
      const option = this.options.find(option => option.value === value);
      if (option) {
        this.paramValue = deepClone(option);
      } else if (value) {
        this.customValue = value;
        this.paramValue = { name: value, value };
      } else {
        this.paramValue = value;
      }
      this.paramKey = param;
    },
    changeValue(value) {
      const finalValue = typeof this.value.value === 'undefined' ? undefined : '';
      this.paramValue = value || { value: finalValue };

      this.emitChange();
    },
    emitChange() {
      const param = this.paramKey;
      const value = this.paramValue?.value;
      const isParamValid = !/[?&#=%]/gi.test(param);
      const isValueValid = !/[?&#=%]/gi.test(value || '');

      if (isParamValid && isValueValid) {
        this.$emit('change', { param, value });
      }

      this.paramKeyError = isParamValid ? null : 'Invalid key';
      this.paramValueError = isValueValid ? null : 'Invalid value';
    },
    emitBlur(input, { validation: { isValid } }) {
      this[`${input}Error`] = isValid ? null : 'Invalid key';
    },
    addValue(text) {
      if (text) {
        this.customValue = text;
        this.paramValue = { name: text, value: text };
      }
    },
    blur(emitKey) {
      this.$emit(emitKey);
    },
  },
};
</script>
